"use strict";
var _a, _b, _c;
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAppData = exports.getLocales = void 0;
var API_ONE_APPLICATION = (_a = process.env.API_ONE_APPLICATION) !== null && _a !== void 0 ? _a : '';
var API_KEY = (_b = process.env.API_KEY) !== null && _b !== void 0 ? _b : '';
var APPLICATION_ID = (_c = process.env.APPLICATION_ID) !== null && _c !== void 0 ? _c : '';
var APPLICATION_VERSION = process.env.APPLICATION_VERSION;
var headers = new Headers();
headers.append('X-Header-AppId', APPLICATION_ID);
headers.append('X-Header-ApiKey', API_KEY);
if (APPLICATION_VERSION) {
    headers.append('X-Header-versionId', APPLICATION_VERSION);
}
headers.append('Content-Type', 'application/json');
var getLocales = function () {
    return fetch("".concat(API_ONE_APPLICATION, "/locales"), { headers: headers, method: 'GET' }).then(function (response) {
        if (!response.ok) {
            return Promise.reject(new Error("GetLocales request failed: ".concat(response.statusText)));
        }
        return response.json();
    });
};
exports.getLocales = getLocales;
var getAppData = function (localeId) {
    return fetch("".concat(API_ONE_APPLICATION, "/applications/locale/").concat(localeId), {
        //return fetch(`${API_ONE_APPLICATION}/applications/sections`, {
        headers: headers,
        method: 'GET'
    }).then(function (response) {
        if (!response.ok) {
            return Promise.reject(new Error("GetAppData request failed: ".concat(response.statusText)));
        }
        return response.json();
    });
};
exports.getAppData = getAppData;
